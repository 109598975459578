<template>
  <div>
    <table-page dataName="list"
                ref="table"
                :search="search"
                @on-success="fetchSuccess"
                :params="{equity_id:$route.query.id}"
                :request="getEquityRecord">
      <template slot="button">
        <FileExport :params="exportParams"
                    v-if="exportParams">导出数据</FileExport>
      </template>

      <el-table-column prop="user_name"
                       show-overflow-tooltip
                       label="姓名">
      </el-table-column>
      <el-table-column prop="user_tel"
                       show-overflow-tooltip
                       label="手机号码">
      </el-table-column>
      <el-table-column prop="member_id_code"
                       show-overflow-tooltip
                       label="身份证号">
      </el-table-column>
      <el-table-column prop="member_type"
                       show-overflow-tooltip
                       label="人群类型">
      </el-table-column>
      <el-table-column prop="openid"
                       show-overflow-tooltip
                       label="OPENID">
      </el-table-column>

      <el-table-column prop="received_at"
                       show-overflow-tooltip
                       label="提交时间">
      </el-table-column>
      <el-table-column show-overflow-tooltip
                       label="操作">
        <template slot-scope="scope">
          <el-button type="text"
                     @click="toDetail(scope)">查看详情</el-button>
        </template>
      </el-table-column>

    </table-page>
    <Detail ref='detail' />
  </div>

</template>

<script>
import Detail from './components/Detail'
import FileExport from '@/components/FileExport'
import TablePage from '@/components/TablePage'
import { getEquityRecord } from '@/api/equity'
export default {
  name: 'EquityRecord',
  data () {
    return {
      exportParams: null,
      getEquityRecord,
      dialogVisible: false,
      searchParams: {},
      exportUrl: '',
      search: [{
        type: 'input',
        name: '姓名',
        placeholder: '单行输入',
        key: 'name',
        value: ''
      }, {
        type: 'input',
        name: '手机号码',
        placeholder: '单行输入',
        key: 'tel',
        value: ''
      }, {
        type: 'input',
        name: '身份证号码',
        placeholder: '单行输入',
        key: 'id_code',
        value: ''
      }, {
        type: 'input',
        name: 'openid',
        placeholder: '单行输入',
        key: 'openid',
        value: ''
      }, {
        type: 'select',
        key: 'threshold',
        name: '人群类型',
        list: [
          {
            value: 'ALL',
            label: '普通会员'
          },
          {
            value: 'CRM_MEMBER',
            label: '实名会员'
          }
        ]
      }]
    }
  },
  components: {
    TablePage,
    Detail,
    FileExport
  },
  filters: {
    prizeName (val) {
      const prizeTypes = {
        ENVELOPE: '红包',
        COUPON: '代金券',
        KIND: '邮寄实物',
        VERIFY: '网点核销'
      }
      return prizeTypes[val]
    }
  },
  methods: {
    onSearch (e) {
      this.searchParams = e
      this.$nextTick(() => {
        this.$refs.table.fetch()
      })
    },
    fetchSuccess (e) {
      this.exportParams = e.export
    },
    exportData (url) {
      window.open(url, '_blank')
    },
    toDetail (scope) {
      this.$refs.detail.showDetail(scope)
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
