<template>
  <div class="">
    <el-dialog title="详情"
               :close-on-click-modal='false'
               :modal-append-to-body="false"
               :visible.sync="dialogVisible"
               width="70%">
      <div class="content">
        <el-table :data="tableData"
                  v-loading="loadding"
                  style="width: 100%">
          <el-table-column prop="equity_name"
                           show-overflow-tooltip
                           label="权益内容">
          </el-table-column>
          <el-table-column prop="no"
                           v-if="type==='CARD_KEY'"
                           show-overflow-tooltip
                           label="兑换码">
          </el-table-column>
          <el-table-column prop="prize_coupon_stock_id"
                           v-if="type==='COUPON'"
                           show-overflow-tooltip
                           label="批次号">
          </el-table-column>
          <el-table-column prop="prize_coupon_id"
                           v-if="type==='COUPON'"
                           show-overflow-tooltip
                           label="代金券id">
          </el-table-column>
          <el-table-column label="核销状态"
                           v-if="type==='COUPON'"
                           show-overflow-tooltip>
            <template slot-scope="scope">
              <span v-if="scope.row.status === 'NORMAL'">
                <el-badge is-dot
                          type="success"
                          class="mr-15"></el-badge>已派发
              </span>
              <span v-else-if="scope.row.status === 'FAIL'">
                <el-badge is-dot
                          type="danger"
                          class="mr-15"></el-badge>派发失败
              </span>
              <span v-else>
                <el-badge is-dot
                          type="warning"
                          class="mr-15"></el-badge>派发中
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <span slot="footer"
            class="dialog-footer">
        <el-button type="primary"
                   @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data () {
    return {
      dialogVisible: false,
      prize: null,
      type: '',
      tableData: [],
      loadding: false
    }
  },
  filters: {
    status (val) {
      const statusTypes = {
        NORMAL: '已领取',
        PENNDING: '派发中',
        FAIL: '派发失败'
      }
      return statusTypes[val]
    }
  },
  components: {
  },
  methods: {
    showDetail (scope) {
      this.dialogVisible = true
      this.type = scope.row.equity_type

      this.$nextTick(() => {
        this.tableData = [{
          ...scope.row
        }]

        this.$forceUpdate()
      })
    }
  },
  mounted () {

  }
}
</script>

<style lang="less" scoped>
</style>
